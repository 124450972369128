p{
    margin: 0;
}
.screen_main{
    height: 100%;
    width: 100%;
    /*background: url("../assets/screen/screenBackground.jpg") ;*/
    /*background-size: cover;*/
    /*-webkit-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-position: center 0;*/
}
.screen_header{
    display:flex;
    background:rgba(0,0,0,0);
    height: 0.47rem;
    justify-content: space-between;
    /*height:60px;*/
    /*background: url("../assets/screen/screenTitle.png") ;*/
    /*background-size: contain;*/
    /*-webkit-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-position: center 0;*/
}
.header_left{
    width: 41%;
    height: 100%;
}
.header_center{
    font-size:0.22rem;
    font-family:"微软雅黑";
    margin:0;
    padding:0;
    border:0;
    color:white;
    position:absolute;
    width: 100%;
    height: 100%;
    line-height: 0.47rem;
    text-align:center;

    /*top:3%;*/
    /*left:50%;*/
    /*-webkit-transform: translate(-50%,-50%);*/
    /*-moz-transform: translate(-50%,-50%);*/
    /*transform:translate(-50%,-50%);*/
}
.header_right{
    width:42%;
    height: 100%;
}

.screen_body_left{
    display:flex;
    background:rgba(0,0,0,0);
    flex-direction:column;
}
.screen_body_right{
    display:flex;
    background:rgba(0,0,0,0);
    flex-direction:column;
}
.screen_body_left_first{
    /*width: 150px;*/
    /*height: 306px;*/
    background: #000000;
    background: rgba(0,0,0,0.14);
    border-radius: 5px;
    width: 90%;
    margin: 5%;
    height: 60%;
    flex-grow: 1;
    display: flex;
    /*margin:10px 10px 10px 10px;*/
    /*background:red;*/
}
.screen_body_left_second{
    width: 90%;
    height: 34%;
    flex-grow: 1;
    background: #000000;
    background: rgba(0,0,0,0.14);
    margin: 5%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
    /*margin:10px 10px 10px 10px;*/
}
.screen_body_left_second_number{
    /*width: 0.1rem;*/
    /*height: 12px;*/
    line-height: 22px;
    margin: 0 5px;
    font-size: 28px;
    /*font-family: Adobe Heiti Std;*/
    font-weight: bold;
    color: #0064FF;
}
.screen_body_left_second_word{
    /*width: 0.2rem;*/
    /*height: 8px;*/
    line-height: 22px;
    font-size: 14px;
    /*font-family: Source Han Sans CN;*/
    font-weight: bold;
    color: #FFFFFF;
    opacity: 0.6;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.screen_body_center{
    padding: 0;
    background: white;
    width: 98%;
    height: 95%;
    margin: 1.3%;
}

.screen_body_center_waringV{
    position: absolute;
    width: 2.1rem;
    height: 1.65rem;
    left: 0.242rem;
    bottom:0.24rem;
    /*bottom: 3.753rem;*/
    z-index: 1;
}
.screen_body_center_pic{
    position: absolute;
    /*background: rgba(0,0,0,0);*/
    width: 2.3rem;
    height: 1.9rem;
    left: 0.1rem;
    bottom:0.1rem;
    z-index: 2;
    /*bottom: 2.0rem;*/
}

.screen_body_center_waringC{
    position: relative;
    width:100%;
    height:100%;
    bottom: 0.25rem;
    background:#BBBBBB;
}


.screen_body_right_first{
    /*width: 100%;*/
    /*height: 66%;*/
    /*flex-grow: 1;*/
    /*!*margin:10px 10px 10px 10px;*!*/
    /*background:red;*/
    /*width: 150px;*/
    /*height: 306px;*/
    background: #000000;
    background: rgba(0,0,0,0.14);
    border-radius: 5px;
    width: 90%;
    margin: 5% 5% 0 5%;
    height: 35%;
    flex-grow: 1;
    /*margin:10px 10px 10px 10px;*/
    /*background:red;*/
}
.screen_body_right_three{
    width: 90%;
    height: 25%;
    flex-grow: 1;
    background: #000000;
    background: rgba(0,0,0,0.14);
    margin: 5% 5% 0 5%;
    border-radius: 5px;

    /*margin:10px 10px 10px 10px;*/
}
.screen_body_right_schoolNuber{
    width: 90%;
    padding:10px 0;
    flex-grow: 1;
    background: #000000;
    background: rgba(0,0,0,0.14);
    margin: 5% 5% 0 5%;
    border-radius: 5px;
}
.screen_body_right_second{
    width: 90%;
    height: 32%;
    flex-grow: 1;
    background: #000000;
    background: rgba(0,0,0,0.14);
    margin: 5% 5% 0 5%;
    border-radius: 5px;

    /*margin:10px 10px 10px 10px;*/
}
.screen_body_title{
    color:white;
    font-size:0.1rem;
    margin:0;
    margin-left:0.1rem;
}
/* .screen_body_title{
    --interval: 1s;
    display: block;
    text-shadow: 
      0 0 10px var(--color1),
      0 0 20px var(--color2),
      0 0 40px var(--color3),
      0 0 80px var(--color4);
    will-change: filter, color;
    filter: saturate(60%);
    animation: flicker steps(100) var(--interval) 1s infinite;
  }
.screen_body_title {
    color: azure;
    --color1: azure;
    --color2: aqua;
    --color3: dodgerblue;
    --color4: blue;
    font-family: Sriracha;
    font-size: 24px;
} */
.screen_body_left_time{
    position: absolute;
    width:100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0.08rem;
}
.screen_body_right_time{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-65%);
    font-size: 0.08rem;
}
.screen_body_right_info{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2470EA;
    padding-bottom:5px;
}
.screen_body_left .ant-tree{
    background: rgba(0,0,0,0);
}
.black_overlay{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #bbbbbb;
    z-index: 1001;
    /* z-index 属性设置元素的堆叠顺序。*/
    opacity: .50;
    /* opacity 属性设置元素的不透明级别。*/
}
.white_content {
    position: absolute;
    top: 20%;
    border: 1px solid #bbbbbb;
    border-radius: 20px;
    background-color: white;
    z-index: 1002;
    /*层级要比.black_overlay高，这样才能显示在它前面*/
    overflow: auto;
    left: 30%;
    width: 850px;
    height: 550px;
}