.screen_main {
  .screen_body_left {
    display: flex;
    background: rgba(0, 0, 0, 0);
    flex-direction: column;

    .deviceBorder {
      width: 100%;
      padding: 5px;
      margin-top: 5px;
      text-align: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
    }
  }

  .tabBar {
    height: 100%;

    .ant-tabs-content {
      height: 100%;
    }

    .close_btn {
      float: right;
      z-index: 100;
      top: 0;
      right: 0;
      position: absolute;
    }

    .four {
      width: 48.5%;
      height: 47%;
      margin: 5px;
      position: relative;
    }

    .nine {
      width: 32%;
      height: 30.3%;
      margin: 5px;
      position: relative;
    }

    .container-shell {
      padding: 0;
    }

  }
}





@primary-color: #1890FF;