.video{
    background-image:url("../../assets/videoImages/adminBack.png");
    background-size: 100% 100%;
}
.fileList:hover{
    background: #61dafb;
    cursor:pointer;
}
.fileList{
    margin-top: 10px;
    font-size: 17px;
}
.fileList{
    margin-top: 10px;
    font-size: 17px;
    color:white
}
.fileListLight{
    margin-top: 10px;
    font-size: 17px;
    background: #61dafb;
    color:white
}