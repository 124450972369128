.header_dark{
  background: #1A2F50;
  //background: rgba(243,248,254);
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  height:96px;
  .header_logo{
    width: 450px;
    height: 100%;
    padding: 20px 20px 20px 10px;
    display: flex;
    align-items: center;
    .header_logo_icon{
      width:256px;
      height: 60px;
    }
    .header_logo_info{
      white-space: nowrap;
      display: flex;
      color: white;
      flex-direction: column;
    }
  }

  .header_menu{
    height: 100%;
    display: flex;
    .header_user{
      margin-left: 40px;
      margin-right: 40px;
      width: 60px;
      padding: 20px 0px;
    }
  }


}

.header_light{
  background: #aaaaaa;
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  height:96px;
  .header_logo{
    width: 450px;
    height: 100%;
    padding: 20px 20px 20px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .header_logo_icon{
      width:60px;
      height: 100%;

    }
    .header_logo_info{
      white-space: nowrap;
      display: flex;
      color: white;
      flex-direction: column;
    }
  }

  .header_menu{
    height: 100%;
    display: flex;
    .header_user{
      margin-left: 40px;
      margin-right: 40px;
      width: 60px;
      padding: 20px 0px;
    }
  }


}
@primary-color: #1890FF;