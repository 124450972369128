.selectProvince{
  background: url("../../assets/videoImages/login3PageBack.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@primary-color: #1890FF;